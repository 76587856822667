export const useSyncStore = defineStore({
  id: 'sync-store',
  state: () => {
    return {
      bot: null,
      synced: false
    }
  },
  actions: {
    setBot(flag) {
      this.bot = flag
    },
    setSynced(flag) {
      this.synced = flag
    }
  },
  getters: {
    isBot: state => !!state.bot,
    isSynced: state => !!state.synced
  }
})
