<template>
  <div class="absolute z-1">
    <div
      v-for="tag in props.tags"
      :key="tag.code"
      class="flex mb-2 max-w-fit items-center p-2 rounded"
      :style="{ 'background-color': tagsTypes[tag.code]?.color }"
      @mouseenter="tagsVisibleState[tag.code] = true"
      @mouseleave="tagsVisibleState[tag.code] = false"
    >
      <component :is="tagsTypes[tag.code]?.icon" />
      <transition name="slide-fade">
        <span
          v-if="tagsVisibleState[tag.code]"
          :class="
            tag.code === 'product_tag_black_friday'
              ? 'text-white'
              : 'text-black'
          "
          class="flex items-center ml-2 text-[10px] h-[10px] font-semibold ease-in-out duration-200"
        >
          {{ tag.name }}
        </span>
      </transition>
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n()

const props = defineProps({
  tags: {
    type: Array,
    default: () => []
  }
})

const tagsTypes = {
  product_tag_promotion: {
    name: t('Акція'),
    icon: resolveComponent('IconsTagsPromotion'),
    color: '#FFE5CD'
  },
  product_tag_new: {
    name: t('Новинка'),
    icon: resolveComponent('IconsTagsNew'),
    color: '#D0FF89'
  },
  product_tag_outlet: {
    name: t('Стокова ціна'),
    icon: resolveComponent('IconsTagsDiscount'),
    color: '#FFD6CD'
  },
  product_tag_brak: {
    name: t('Товар з уцінкою'),
    icon: resolveComponent('IconsTagsArrowDown'),
    color: '#FFD6CD'
  },
  product_tag_ordered: {
    name: t('Очікується'),
    icon: resolveComponent('IconsTagsWaiting'),
    color: '#CDF0FF'
  },
  product_tag_out_of_stock_soon: {
    name: t('Скоро закінчується'),
    icon: resolveComponent('IconsTagsTimer'),
    color: '#FFF7CD'
  },
  product_tag_pickup: {
    name: t('Самовивіз'),
    icon: resolveComponent('IconsTagsSelfDelivery'),
    color: '#D6DCFF'
  },
  product_tag_black_friday: {
    name: 'Black Friday',
    icon: resolveComponent('IconsTagsBlackFriday'),
    color: '#464646'
  },
  product_tag_installment: {
    name: t('Покупка частинами'),
    icon: resolveComponent('IconsTagsParts'),
    color: '#EFF3F3'
  },
  product_tag_expert_review: {
    name: t('Експерт рекомендує'),
    icon: resolveComponent('IconsTagsExpert'),
    color: '#8bcbfd'
  }
}

const tagsVisibleState = ref({
  product_tag_promotion: false,
  product_tag_new: false,
  product_tag_outlet: false,
  product_tag_brak: false,
  product_tag_ordered: false,
  product_tag_out_of_stock_soon: false,
  product_tag_pickup: false,
  product_tag_black_friday: false,
  product_tag_installment: false
})
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-15px);
  opacity: 0;
}
</style>
