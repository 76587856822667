<template>
  <div
    :class="[
      'flex flex-row place-content-between w-full mb-3.5 md:mb-7 items-center min-h-[30px] md:min-h-0 pr-[80px]',
      headerClass
    ]"
  >
    <div class="space-y-3 lg:space-y-3">
      <div v-if="props.title" :class="titleClass">
        {{ props.title }}
      </div>
      <div
        v-if="props.subTitle"
        class="text-gray-mud text-[10px] lg:text-sm md:text-[10x] sm:text-[10px]"
      >
        {{ props.subTitle }}
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  titleClass: {
    type: String,
    default: ''
  },
  headerClass: {
    type: String,
    default: ''
  }
})
</script>
