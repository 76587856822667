<template>
  <nav aria-label="Breadcrumb" class="pt-4 mb-7">
    <ol role="list" class="flex flex-wrap items-center text-[12px]">
      <li v-if="props.backButton" class="flex items-center xs:mb-3 md:mb-0">
        <a
          href="#"
          class="flex items-center link-mud"
          @click.prevent="router.back()"
        >
          <ArrowLeftIcon class="h-3.5" /> &nbsp; <span>Назад</span>
        </a>
        <IconsCatalogNavigationSeparator />
      </li>
      <li class="flex items-center">
        <NuxtLink to="/" class="link-green">
          <IconsHome class="w-[16px] h-[16px]" />
        </NuxtLink>
      </li>
      <li
        v-for="breadcrumb in props.breadcrumbs"
        :key="breadcrumb.name"
        class="flex items-center"
      >
        <IconsCatalogNavigationSeparator />
        <template v-if="breadcrumb.to">
          <AppBreadcrumbsLink :breadcrumb="breadcrumb" />
        </template>
        <template v-else-if="breadcrumb.options">
          <AppBreadcrumbsDropdown class="z-[110]" :breadcrumb="breadcrumb" />
        </template>
        <template v-else>
          <span class="text-gray-mud">{{ breadcrumb.name }}</span>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { ArrowLeftIcon } from '@heroicons/vue/24/solid'
const router = useRouter()
const props = defineProps({
  breadcrumbs: {
    type: Array,
    default: () => []
  },
  backButton: {
    type: Boolean,
    default: false
  }
})
</script>
