import api from '../api.client'

const path = '/api/v2/shop/products'
const filterPath = '/api/v2/shop/taxons'

export default {
  getAll: (query = {}, options = {}) =>
    api({ auth: false, ...options }).get(path, query),
  getOneBySlug: (slug, options) =>
    api({ auth: false, ...options }).get(`${path}-by-slug/${slug}`),
  getOneByVariantSlug: (slug, options = {}) =>
    api(options).get(`${path}-by-variant-slug/${slug}`),
  getOne: (code, options) => api({ ...options }).get(`${path}/${code}`),
  getSearchProducts: (phrase, options) =>
    api({ auth: false, ...options }).get(`${path}-search`, phrase),
  getFilteredProducts: (code, query = {}, options = {}) =>
    api({ auth: false, ...options }).get(`${path}-filtered/${code}`, query),
  getProductsByBrand: (slug, query = {}, options = {}) =>
    api({ auth: false, ...options }).get(
      `${path}-filtered-by-brand/${slug}`,
      query
    ),
  getFilters: (code, query = {}, options = {}) =>
    api({ auth: false, ...options }).get(
      `${filterPath}/${code}?withFilters=true`,
      query
    ),
  getRelatedProducts: ({ code, query }, options = {}) =>
    api({ auth: false, ...options }).get(`${path}/${code}/related`, query),
  getTuningProducts: (pageName, options = {}) =>
    api({ auth: false, ...options }).get(`${path}-page/${pageName}`),
  getMoreTuningProducts: ({ taxonCode, query }, options = {}) =>
    api({ auth: false, ...options }).get(`${path}-filtered/${taxonCode}`, query)
}
