export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('scroll-animate', {
    mounted(el, binding) {
      const animations = {
        'fade-in-right': 'translateX(70px)',
        'fade-in-left': 'translateX(-70px)',
        'fade-in-down': 'translateY(70px)',
        'fade-in-top': 'translateY(-70px)'
      }

      const transform = animations[binding.value] || 'none'

      Object.assign(el.style, {
        opacity: '0',
        transform,
        transition: 'all 2s ease-in-out'
      })

      useIntersectionObserver(el, ([{ isIntersecting }]) => {
        if (isIntersecting) {
          Object.assign(el.style, {
            opacity: '1',
            transform: 'translate(0, 0)'
          })
        }
      })
    }
  })
})
