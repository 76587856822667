<template>
  <div ref="listRef" class="w-full">
    <Header
      :title="props.title"
      :sub-title="props.subTitle"
      :title-class="titleClass"
      :header-class="headerClass"
    />

    <CategoryList
      v-if="props.categoryList?.length"
      :list="props.categoryList"
      :selected-category-id="selectedCategoryId"
      :select-category="handleSelectCategory"
    />
    <div class="relative list-container">
      <template v-if="swiperEl?.mounted">
        <span
          ref="navigationButtonNext"
          class="navigation-button-next"
          :class="props.navigationClass"
        >
          <NavigationButton />
        </span>

        <span
          ref="navigationButtonPrev"
          class="navigation-button-prev"
          :class="props.navigationClass"
        >
          <NavigationButton to-left />
        </span>
      </template>
      <div class="overflow-x-hidden">
        <swiper
          :key="key"
          :scrollbar="{
            hide: hideScrollbar,
            draggable: true,
            horizontalClass: 'scrollbar-custom-styles',
            el: '.swiper-scrollbar'
          }"
          :navigation="
            showNavigation && {
              nextEl: navigationButtonNext,
              prevEl: navigationButtonPrev
            }
          "
          :autoplay="
            props.autoplay
              ? {
                delay: 3000
              }
              : false
          "
          :slides-per-view="props.slidesPerView"
          :grid="{
            rows: props.rows,
            fill: 'row'
          }"
          :modules="[
            SwiperScrollbar,
            SwiperNavigation,
            SwiperVirtual,
            SwiperGrid,
            SwiperPagination,
            SwiperAutoplay
          ]"
          :space-between="spaceBetween"
          :breakpoints="props.breakpoints"
          :virtual="props.virtual"
          :hash-navigation="props.navigation"
          @swiper="getRef"
          @slide-change="onSlideChange"
          @breakpoint="onBreakpoint"
          @update="onActiveSlidesChange"
        >
          <!--          <swiper-slide-->
          <!--            v-for="(slide, index) in props.slides"-->
          <!--            :key="uniqueId(index)"-->
          <!--            :class="props.slideClass"-->
          <!--            :virtual-index="index"-->
          <!--          >-->
          <swiper-slide
            v-for="(slide, index) in props.slides"
            :key="index"
            :class="props.slideClass"
            :virtual-index="index"
          >
            <slot :slide="slide"></slot>
          </swiper-slide>
          <template #container-end>
            <div ref="footerRef" class="swiper-footer">
              <div v-if="!props.hideScrollbar" class="swiper-scrollbar"></div>
              <div
                v-if="props.showDots"
                class="flex flex-wrap space-x-3 mx-auto justify-center w-full"
              >
                <div
                  v-for="index in props.slides?.length"
                  :key="index"
                  class="indicator h-[3px] w-4 rounded bg-green-ibis mb-2"
                  :class="[
                    currentIndex === index - 1 ? 'opacity-100' : 'opacity-30'
                  ]"
                ></div>
              </div>
              <Link
                v-if="listLink"
                :click-handler="listLink?.action"
                :label="listLink.label"
                :to="listLink.to"
              />
            </div>
          </template>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script setup>
import 'swiper/css'
import 'swiper/css/virtual'
import 'swiper/css/grid'
import uniqueId from 'lodash/uniqueId'
import get from 'lodash/get'

import Link from './Link.vue'
import Header from './Header.vue'
import CategoryList from './CategoryList.vue'
import NavigationButton from './NavigationButton.vue'

const navigationButtonPrev = ref(null)
const navigationButtonNext = ref(null)

const getRef = swiperInstance => {
  swiperEl.value = swiperInstance
}

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  slides: {
    type: Array,
    default: () => []
  },
  listLink: {
    type: Object,
    default: null
  },
  categoryList: {
    type: Array,
    default: () => []
  },
  selectCategory: {
    type: Function,
    default: null
  },
  titleClass: {
    type: String,
    default:
      'uppercase text-green-swamp font-bold text-lg lg:text-3xl md:text-lg sm:text-lg'
  },
  hideScrollbar: {
    type: Boolean,
    default: false
  },
  showDots: {
    type: Boolean,
    default: false
  },
  headerClass: {
    type: String,
    default: ''
  },
  slideClass: {
    type: String,
    default: ''
  },
  slidesPerView: {
    type: [Number, String],
    default: 1
  },
  rows: {
    type: [Number, String],
    default: 1
  },
  spaceBetween: {
    type: [Number, String],
    default: 0
  },
  breakpoints: {
    type: Object,
    default: () => {}
  },
  slidePerView: {
    type: [Number, String],
    default: 1
  },
  linkClass: {
    type: String,
    default: ''
  },
  navigationClass: {
    type: String,
    default: ''
  },
  showNavigation: {
    type: Boolean,
    default: true
  },
  virtual: {
    type: Boolean,
    default: false
  },
  navigation: {
    type: Boolean,
    default: false
  },
  activeSlide: {
    type: Number,
    default: 0
  },
  autoplay: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['activeSlides', 'activeSlidesIndexes', 'swiper'])
const listRef = ref(null)
const swiperEl = ref(null)
const selectedCategoryId = ref(
  props.categoryList.length && props.categoryList[0].id
)
const footerRef = ref(null)
const key = ref(uniqueId())

const currentIndex = ref(0)

const handleSelectCategory = category => {
  selectedCategoryId.value = category.id
  props.selectCategory(category)
}

const onSlideChange = swiper => {
  onActiveSlidesChange(swiper)
}
const onBreakpoint = swiper => {
  onActiveSlidesChange(swiper)
}

const onActiveSlidesChange = swiper => {
  const slidesPerView = get(
    props,
    ['breakpoints', swiper.currentBreakpoint, 'slidesPerView'],
    props.slidesPerView
  )
  currentIndex.value = swiper.activeIndex
  const { activeIndex } = swiper
  const activeSlides = []
  const activeSlidesIndexes = []
  for (let i = activeIndex; i < activeIndex + slidesPerView; i++) {
    const slide = get(props.slides, i)
    if (slide) {
      activeSlides.push(slide)
      activeSlidesIndexes.push(i)
    }
  }
  emit('activeSlidesIndexes', activeSlidesIndexes)
  emit('activeSlides', activeSlides)
  emit('swiper', swiper)
}

const goToSlide = index => {
  if (swiperEl.value) {
    swiperEl.value.slideTo(index)
  }
}

onMounted(() => {
  if (props.activeSlide) {
    goToSlide(props.activeSlide)
  }
})
</script>

<style>
.list-container .swiper-scrollbar-drag {
  background-color: rgba(0, 120, 95, 1);
  border-radius: 50px;
}

.list-container .swiper-scrollbar {
  left: 0;
  cursor: pointer;
  transition: height 0.1s !important;
}

.list-container .swiper-scrollbar:hover {
  height: 7px;
}

.list-container .swiper-scrollbar-drag {
  cursor: move;
}

.scrollbar-custom-styles {
  position: static;
  height: 4px;
  flex-grow: 1;
  background-color: rgba(0, 120, 95, 0.1) !important;
}

.swiper-footer {
  display: flex;
  align-items: center;
  min-height: 7px;
}
</style>
<style scoped lang="postcss">
.navigation-button-next,
.navigation-button-prev {
  @apply absolute z-[2] top-[50%] -translate-y-[110%];
  /* @apply absolute z-[2] top-[50%] -translate-y-[110%] hidden md:block; */
  /* @apply absolute z-[2] top-0 -translate-y-[60px] md:top-[50%] md:-translate-y-[110%]; */
}
.navigation-button-next {
  /* @apply right-0 translate-x-0  md:translate-x-[50%]; */
  @apply right-0 translate-x-[30%] md:translate-x-[50%];
}
.navigation-button-prev {
  /* @ apply right-0 -translate-x-[120%] md:right-auto md:left-0 md:-translate-x-[50%]; */
  @apply right-auto left-0 -translate-x-[30%] md:-translate-x-[50%];
}

.navigation-button-next.swiper-button-disabled,
.navigation-button-prev.swiper-button-disabled {
  @apply hidden;
}
</style>
