import { default as index79SSMn61hyMeta } from "/builds/ibis_dev/ibis_frontend/pages/[category]/[subCategory]/index.vue?macro=true";
import { default as _91_46_46_46filters_93RfIqxELpBaMeta } from "/builds/ibis_dev/ibis_frontend/pages/[category]/[subCategory]/search/[...filters].vue?macro=true";
import { default as indexfL7TmlPC7XMeta } from "/builds/ibis_dev/ibis_frontend/pages/[category]/[subCategory]/search/index.vue?macro=true";
import { default as _91slug_93nqrEMtMi1PMeta } from "/builds/ibis_dev/ibis_frontend/pages/[category]/details/[slug].vue?macro=true";
import { default as indexHTvxiJ2nl2Meta } from "/builds/ibis_dev/ibis_frontend/pages/[category]/index.vue?macro=true";
import { default as _91_46_46_46filters_93c9Pi8QM3qyMeta } from "/builds/ibis_dev/ibis_frontend/pages/[category]/search/[...filters].vue?macro=true";
import { default as indexCLWHxxNl4kMeta } from "/builds/ibis_dev/ibis_frontend/pages/[category]/search/index.vue?macro=true";
import { default as _404ty4FnKxr7zMeta } from "/builds/ibis_dev/ibis_frontend/pages/404.vue?macro=true";
import { default as indexiYXxAWJLuYMeta } from "/builds/ibis_dev/ibis_frontend/pages/about/index.vue?macro=true";
import { default as _91slug_93JcYUZAA1MFMeta } from "/builds/ibis_dev/ibis_frontend/pages/brand/[slug].vue?macro=true";
import { default as indexYAiqfVezOvMeta } from "/builds/ibis_dev/ibis_frontend/pages/brand/index.vue?macro=true";
import { default as indexsy20Gv0rW1Meta } from "/builds/ibis_dev/ibis_frontend/pages/cabinet/delivery/index.vue?macro=true";
import { default as indexrebQbXFJ8MMeta } from "/builds/ibis_dev/ibis_frontend/pages/cabinet/discounts/index.vue?macro=true";
import { default as indexLBdjOPWEz1Meta } from "/builds/ibis_dev/ibis_frontend/pages/cabinet/favorites/index.vue?macro=true";
import { default as indexgXu2SWj3UfMeta } from "/builds/ibis_dev/ibis_frontend/pages/cabinet/index.vue?macro=true";
import { default as index9iTg9b1DviMeta } from "/builds/ibis_dev/ibis_frontend/pages/cabinet/orders/index.vue?macro=true";
import { default as indexsJ6Gx3MpLcMeta } from "/builds/ibis_dev/ibis_frontend/pages/cabinet/preorders/index.vue?macro=true";
import { default as indexMZy7bmNOjPMeta } from "/builds/ibis_dev/ibis_frontend/pages/cabinet/reviews/index.vue?macro=true";
import { default as cabinetqI0qy0kubcMeta } from "/builds/ibis_dev/ibis_frontend/pages/cabinet.vue?macro=true";
import { default as indexhWl2ypYny8Meta } from "/builds/ibis_dev/ibis_frontend/pages/checkout-preorder/index.vue?macro=true";
import { default as indexWZv8VmtXWhMeta } from "/builds/ibis_dev/ibis_frontend/pages/checkout-store/index.vue?macro=true";
import { default as confirmationx2rNatqOc2Meta } from "/builds/ibis_dev/ibis_frontend/pages/checkout/confirmation.vue?macro=true";
import { default as indexpf8f90G5QdMeta } from "/builds/ibis_dev/ibis_frontend/pages/checkout/index.vue?macro=true";
import { default as compareYjJfO44FM3Meta } from "/builds/ibis_dev/ibis_frontend/pages/compare.vue?macro=true";
import { default as advertV7L024dvRfMeta } from "/builds/ibis_dev/ibis_frontend/pages/contacts/advert.vue?macro=true";
import { default as chat_45botwsGBZFGrllMeta } from "/builds/ibis_dev/ibis_frontend/pages/contacts/chat-bot.vue?macro=true";
import { default as for_45customers9i9WcWE52oMeta } from "/builds/ibis_dev/ibis_frontend/pages/contacts/for-customers.vue?macro=true";
import { default as indexO9aRMqLvVzMeta } from "/builds/ibis_dev/ibis_frontend/pages/contacts/index.vue?macro=true";
import { default as optovyi_45viddilgP1us8kIaSMeta } from "/builds/ibis_dev/ibis_frontend/pages/contacts/optovyi-viddil.vue?macro=true";
import { default as servis_45centrzYAgBeKw5cMeta } from "/builds/ibis_dev/ibis_frontend/pages/contacts/servis-centr.vue?macro=true";
import { default as tir4Iu0zllMFyMeta } from "/builds/ibis_dev/ibis_frontend/pages/contacts/tir.vue?macro=true";
import { default as contactsIziLgH5NVQMeta } from "/builds/ibis_dev/ibis_frontend/pages/contacts.vue?macro=true";
import { default as indexLfFLr0OmfbMeta } from "/builds/ibis_dev/ibis_frontend/pages/cookies-policy/index.vue?macro=true";
import { default as indexkZk5atsQGuMeta } from "/builds/ibis_dev/ibis_frontend/pages/dk/index.vue?macro=true";
import { default as indexbeXfaHeuQoMeta } from "/builds/ibis_dev/ibis_frontend/pages/dostavka-i-oplata/index.vue?macro=true";
import { default as indexQe6KpwiZcuMeta } from "/builds/ibis_dev/ibis_frontend/pages/faq/index.vue?macro=true";
import { default as indexbBTkRqbHmiMeta } from "/builds/ibis_dev/ibis_frontend/pages/garantijnye-objazatelstva/index.vue?macro=true";
import { default as indexnUKvel46B0Meta } from "/builds/ibis_dev/ibis_frontend/pages/gift-certificate/index.vue?macro=true";
import { default as indexNUWeDwqJN2Meta } from "/builds/ibis_dev/ibis_frontend/pages/index.vue?macro=true";
import { default as indexySX680cHUgMeta } from "/builds/ibis_dev/ibis_frontend/pages/mislivstvo-dileri-v-regionakh/index.vue?macro=true";
import { default as index6BYmPx8HZnMeta } from "/builds/ibis_dev/ibis_frontend/pages/obmin-ta-povernennya/index.vue?macro=true";
import { default as _91taxonSlug_93Ll7H3R1L2oMeta } from "/builds/ibis_dev/ibis_frontend/pages/post/[slug]/[taxonSlug].vue?macro=true";
import { default as indexWKFMxJ85inMeta } from "/builds/ibis_dev/ibis_frontend/pages/post/[slug]/index.vue?macro=true";
import { default as index9NlzkIpl03Meta } from "/builds/ibis_dev/ibis_frontend/pages/post/index.vue?macro=true";
import { default as indexSxItRX865ZMeta } from "/builds/ibis_dev/ibis_frontend/pages/pravila-garnogo-komentatora/index.vue?macro=true";
import { default as _91_46_46_46any_93d5sYLuIRsoMeta } from "/builds/ibis_dev/ibis_frontend/pages/products/[...any].vue?macro=true";
import { default as indexYOhnjffxIwMeta } from "/builds/ibis_dev/ibis_frontend/pages/programa-relodingu/index.vue?macro=true";
import { default as indexweTGwg1BpvMeta } from "/builds/ibis_dev/ibis_frontend/pages/publichna-zvitnist/index.vue?macro=true";
import { default as indexLRiBbOlKsSMeta } from "/builds/ibis_dev/ibis_frontend/pages/ribalka-magazini-i-dileri/index.vue?macro=true";
import { default as indexpizmTh4ooYMeta } from "/builds/ibis_dev/ibis_frontend/pages/search/index.vue?macro=true";
import { default as indexsZNPcQeVQuMeta } from "/builds/ibis_dev/ibis_frontend/pages/servis-generatoriv/index.vue?macro=true";
import { default as indexEtx6YY1VJJMeta } from "/builds/ibis_dev/ibis_frontend/pages/servisnyy-centr-favorite-select-brain/index.vue?macro=true";
import { default as indexCb34ilFurqMeta } from "/builds/ibis_dev/ibis_frontend/pages/servisnyy-centr-ibis/index.vue?macro=true";
import { default as sync_45cookies_452hZZPV9OEB4Meta } from "/builds/ibis_dev/ibis_frontend/pages/sync-cookies-2.vue?macro=true";
import { default as sync_45cookiesmL1EI7xNALMeta } from "/builds/ibis_dev/ibis_frontend/pages/sync-cookies.vue?macro=true";
import { default as indexIF7nhDM33EMeta } from "/builds/ibis_dev/ibis_frontend/pages/tuning_ak/index.vue?macro=true";
import { default as indexgXSpbKwNwZMeta } from "/builds/ibis_dev/ibis_frontend/pages/tuning_ar/index.vue?macro=true";
import { default as _91_46_46_46any_936Dss2Q9wJVMeta } from "/builds/ibis_dev/ibis_frontend/pages/ua/[...any].vue?macro=true";
import { default as indexKRvfIaf0KpMeta } from "/builds/ibis_dev/ibis_frontend/pages/uhoda-korystuvacha/index.vue?macro=true";
import { default as index3GQAVi9tlsMeta } from "/builds/ibis_dev/ibis_frontend/pages/uslovyia-pryobrytenyia-oruzhyia/index.vue?macro=true";
export default [
  {
    name: "category-subCategory___uk",
    path: "/:category()/:subCategory()",
    meta: index79SSMn61hyMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/[subCategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "category-subCategory___ru",
    path: "/ru/:category()/:subCategory()",
    meta: index79SSMn61hyMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/[subCategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "category-subCategory-search-filters___uk",
    path: "/:category()/:subCategory()/search/:filters(.*)*",
    meta: _91_46_46_46filters_93RfIqxELpBaMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/[subCategory]/search/[...filters].vue").then(m => m.default || m)
  },
  {
    name: "category-subCategory-search-filters___ru",
    path: "/ru/:category()/:subCategory()/search/:filters(.*)*",
    meta: _91_46_46_46filters_93RfIqxELpBaMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/[subCategory]/search/[...filters].vue").then(m => m.default || m)
  },
  {
    name: "category-subCategory-search___uk",
    path: "/:category()/:subCategory()/search",
    meta: indexfL7TmlPC7XMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/[subCategory]/search/index.vue").then(m => m.default || m)
  },
  {
    name: "category-subCategory-search___ru",
    path: "/ru/:category()/:subCategory()/search",
    meta: indexfL7TmlPC7XMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/[subCategory]/search/index.vue").then(m => m.default || m)
  },
  {
    name: "category-details-slug___uk",
    path: "/:category()/details/:slug()",
    meta: _91slug_93nqrEMtMi1PMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/details/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-details-slug___ru",
    path: "/ru/:category()/details/:slug()",
    meta: _91slug_93nqrEMtMi1PMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/details/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category___uk",
    path: "/:category()",
    meta: indexHTvxiJ2nl2Meta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "category___ru",
    path: "/ru/:category()",
    meta: indexHTvxiJ2nl2Meta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "category-search-filters___uk",
    path: "/:category()/search/:filters(.*)*",
    meta: _91_46_46_46filters_93c9Pi8QM3qyMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/search/[...filters].vue").then(m => m.default || m)
  },
  {
    name: "category-search-filters___ru",
    path: "/ru/:category()/search/:filters(.*)*",
    meta: _91_46_46_46filters_93c9Pi8QM3qyMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/search/[...filters].vue").then(m => m.default || m)
  },
  {
    name: "category-search___uk",
    path: "/:category()/search",
    meta: indexCLWHxxNl4kMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/search/index.vue").then(m => m.default || m)
  },
  {
    name: "category-search___ru",
    path: "/ru/:category()/search",
    meta: indexCLWHxxNl4kMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/[category]/search/index.vue").then(m => m.default || m)
  },
  {
    name: "404___uk",
    path: "/404",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "404___ru",
    path: "/ru/404",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "about___uk",
    path: "/about",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___ru",
    path: "/ru/about",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "brand-slug___uk",
    path: "/brand/:slug()",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/brand/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brand-slug___ru",
    path: "/ru/brand/:slug()",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/brand/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brand___uk",
    path: "/brand",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "brand___ru",
    path: "/ru/brand",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/brand/index.vue").then(m => m.default || m)
  },
  {
    name: cabinetqI0qy0kubcMeta?.name,
    path: "/cabinet",
    meta: cabinetqI0qy0kubcMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet.vue").then(m => m.default || m),
    children: [
  {
    name: "cabinet-delivery___uk",
    path: "delivery",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-discounts___uk",
    path: "discounts",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-favorites___uk",
    path: "favorites",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet___uk",
    path: "",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders___uk",
    path: "orders",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-preorders___uk",
    path: "preorders",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/preorders/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-reviews___uk",
    path: "reviews",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/reviews/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: cabinetqI0qy0kubcMeta?.name,
    path: "/ru/cabinet",
    meta: cabinetqI0qy0kubcMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet.vue").then(m => m.default || m),
    children: [
  {
    name: "cabinet-delivery___ru",
    path: "delivery",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-discounts___ru",
    path: "discounts",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-favorites___ru",
    path: "favorites",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet___ru",
    path: "",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-orders___ru",
    path: "orders",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-preorders___ru",
    path: "preorders",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/preorders/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-reviews___ru",
    path: "reviews",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cabinet/reviews/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "checkout-preorder___uk",
    path: "/checkout-preorder",
    meta: indexhWl2ypYny8Meta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/checkout-preorder/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-preorder___ru",
    path: "/ru/checkout-preorder",
    meta: indexhWl2ypYny8Meta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/checkout-preorder/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-store___uk",
    path: "/checkout-store",
    meta: indexWZv8VmtXWhMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/checkout-store/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-store___ru",
    path: "/ru/checkout-store",
    meta: indexWZv8VmtXWhMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/checkout-store/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation___uk",
    path: "/checkout/confirmation",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/checkout/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation___ru",
    path: "/ru/checkout/confirmation",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/checkout/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout___uk",
    path: "/checkout",
    meta: indexpf8f90G5QdMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___ru",
    path: "/ru/checkout",
    meta: indexpf8f90G5QdMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "compare___uk",
    path: "/compare",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: "compare___ru",
    path: "/ru/compare",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: contactsIziLgH5NVQMeta?.name,
    path: "/contacts",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts.vue").then(m => m.default || m),
    children: [
  {
    name: "contacts-advert___uk",
    path: "advert",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/advert.vue").then(m => m.default || m)
  },
  {
    name: "contacts-chat-bot___uk",
    path: "chat-bot",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/chat-bot.vue").then(m => m.default || m)
  },
  {
    name: "contacts-for-customers___uk",
    path: "for-customers",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/for-customers.vue").then(m => m.default || m)
  },
  {
    name: "contacts___uk",
    path: "",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts-optovyi-viddil___uk",
    path: "optovyi-viddil",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/optovyi-viddil.vue").then(m => m.default || m)
  },
  {
    name: "contacts-servis-centr___uk",
    path: "servis-centr",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/servis-centr.vue").then(m => m.default || m)
  },
  {
    name: "contacts-tir___uk",
    path: "tir",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/tir.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contactsIziLgH5NVQMeta?.name,
    path: "/ru/contacts",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts.vue").then(m => m.default || m),
    children: [
  {
    name: "contacts-advert___ru",
    path: "advert",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/advert.vue").then(m => m.default || m)
  },
  {
    name: "contacts-chat-bot___ru",
    path: "chat-bot",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/chat-bot.vue").then(m => m.default || m)
  },
  {
    name: "contacts-for-customers___ru",
    path: "for-customers",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/for-customers.vue").then(m => m.default || m)
  },
  {
    name: "contacts___ru",
    path: "",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts-optovyi-viddil___ru",
    path: "optovyi-viddil",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/optovyi-viddil.vue").then(m => m.default || m)
  },
  {
    name: "contacts-servis-centr___ru",
    path: "servis-centr",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/servis-centr.vue").then(m => m.default || m)
  },
  {
    name: "contacts-tir___ru",
    path: "tir",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/contacts/tir.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cookies-policy___uk",
    path: "/cookies-policy",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cookies-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "cookies-policy___ru",
    path: "/ru/cookies-policy",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/cookies-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "dk___uk",
    path: "/dk",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/dk/index.vue").then(m => m.default || m)
  },
  {
    name: "dk___ru",
    path: "/ru/dk",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/dk/index.vue").then(m => m.default || m)
  },
  {
    name: "dostavka-i-oplata___uk",
    path: "/dostavka-i-oplata",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/dostavka-i-oplata/index.vue").then(m => m.default || m)
  },
  {
    name: "dostavka-i-oplata___ru",
    path: "/ru/dostavka-i-oplata",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/dostavka-i-oplata/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___uk",
    path: "/faq",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "garantijnye-objazatelstva___uk",
    path: "/garantijnye-objazatelstva",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/garantijnye-objazatelstva/index.vue").then(m => m.default || m)
  },
  {
    name: "garantijnye-objazatelstva___ru",
    path: "/ru/garantijnye-objazatelstva",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/garantijnye-objazatelstva/index.vue").then(m => m.default || m)
  },
  {
    name: "gift-certificate___uk",
    path: "/gift-certificate",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/gift-certificate/index.vue").then(m => m.default || m)
  },
  {
    name: "gift-certificate___ru",
    path: "/ru/gift-certificate",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/gift-certificate/index.vue").then(m => m.default || m)
  },
  {
    name: "index___uk",
    path: "/",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mislivstvo-dileri-v-regionakh___uk",
    path: "/mislivstvo-dileri-v-regionakh",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/mislivstvo-dileri-v-regionakh/index.vue").then(m => m.default || m)
  },
  {
    name: "mislivstvo-dileri-v-regionakh___ru",
    path: "/ru/mislivstvo-dileri-v-regionakh",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/mislivstvo-dileri-v-regionakh/index.vue").then(m => m.default || m)
  },
  {
    name: "obmin-ta-povernennya___uk",
    path: "/obmin-ta-povernennya",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/obmin-ta-povernennya/index.vue").then(m => m.default || m)
  },
  {
    name: "obmin-ta-povernennya___ru",
    path: "/ru/obmin-ta-povernennya",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/obmin-ta-povernennya/index.vue").then(m => m.default || m)
  },
  {
    name: "post-slug-taxonSlug___uk",
    path: "/post/:slug()/:taxonSlug()",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/post/[slug]/[taxonSlug].vue").then(m => m.default || m)
  },
  {
    name: "post-slug-taxonSlug___ru",
    path: "/ru/post/:slug()/:taxonSlug()",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/post/[slug]/[taxonSlug].vue").then(m => m.default || m)
  },
  {
    name: "post-slug___uk",
    path: "/post/:slug()",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/post/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "post-slug___ru",
    path: "/ru/post/:slug()",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/post/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "post___uk",
    path: "/post",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/post/index.vue").then(m => m.default || m)
  },
  {
    name: "post___ru",
    path: "/ru/post",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/post/index.vue").then(m => m.default || m)
  },
  {
    name: "pravila-garnogo-komentatora___uk",
    path: "/pravila-garnogo-komentatora",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/pravila-garnogo-komentatora/index.vue").then(m => m.default || m)
  },
  {
    name: "pravila-garnogo-komentatora___ru",
    path: "/ru/pravila-garnogo-komentatora",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/pravila-garnogo-komentatora/index.vue").then(m => m.default || m)
  },
  {
    name: "products-any___uk",
    path: "/products/:any(.*)*",
    meta: _91_46_46_46any_93d5sYLuIRsoMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/products/[...any].vue").then(m => m.default || m)
  },
  {
    name: "products-any___ru",
    path: "/ru/products/:any(.*)*",
    meta: _91_46_46_46any_93d5sYLuIRsoMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/products/[...any].vue").then(m => m.default || m)
  },
  {
    name: "programa-relodingu___uk",
    path: "/programa-relodingu",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/programa-relodingu/index.vue").then(m => m.default || m)
  },
  {
    name: "programa-relodingu___ru",
    path: "/ru/programa-relodingu",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/programa-relodingu/index.vue").then(m => m.default || m)
  },
  {
    name: "publichna-zvitnist___uk",
    path: "/publichna-zvitnist",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/publichna-zvitnist/index.vue").then(m => m.default || m)
  },
  {
    name: "publichna-zvitnist___ru",
    path: "/ru/publichna-zvitnist",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/publichna-zvitnist/index.vue").then(m => m.default || m)
  },
  {
    name: "ribalka-magazini-i-dileri___uk",
    path: "/ribalka-magazini-i-dileri",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/ribalka-magazini-i-dileri/index.vue").then(m => m.default || m)
  },
  {
    name: "ribalka-magazini-i-dileri___ru",
    path: "/ru/ribalka-magazini-i-dileri",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/ribalka-magazini-i-dileri/index.vue").then(m => m.default || m)
  },
  {
    name: "search___uk",
    path: "/search",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___ru",
    path: "/ru/search",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "servis-generatoriv___uk",
    path: "/servis-generatoriv",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/servis-generatoriv/index.vue").then(m => m.default || m)
  },
  {
    name: "servis-generatoriv___ru",
    path: "/ru/servis-generatoriv",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/servis-generatoriv/index.vue").then(m => m.default || m)
  },
  {
    name: "servisnyy-centr-favorite-select-brain___uk",
    path: "/servisnyy-centr-favorite-select-brain",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/servisnyy-centr-favorite-select-brain/index.vue").then(m => m.default || m)
  },
  {
    name: "servisnyy-centr-favorite-select-brain___ru",
    path: "/ru/servisnyy-centr-favorite-select-brain",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/servisnyy-centr-favorite-select-brain/index.vue").then(m => m.default || m)
  },
  {
    name: "servisnyy-centr-ibis___uk",
    path: "/servisnyy-centr-ibis",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/servisnyy-centr-ibis/index.vue").then(m => m.default || m)
  },
  {
    name: "servisnyy-centr-ibis___ru",
    path: "/ru/servisnyy-centr-ibis",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/servisnyy-centr-ibis/index.vue").then(m => m.default || m)
  },
  {
    name: "sync-cookies-2___uk",
    path: "/sync-cookies-2",
    meta: sync_45cookies_452hZZPV9OEB4Meta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/sync-cookies-2.vue").then(m => m.default || m)
  },
  {
    name: "sync-cookies-2___ru",
    path: "/ru/sync-cookies-2",
    meta: sync_45cookies_452hZZPV9OEB4Meta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/sync-cookies-2.vue").then(m => m.default || m)
  },
  {
    name: "sync-cookies___uk",
    path: "/sync-cookies",
    meta: sync_45cookiesmL1EI7xNALMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/sync-cookies.vue").then(m => m.default || m)
  },
  {
    name: "sync-cookies___ru",
    path: "/ru/sync-cookies",
    meta: sync_45cookiesmL1EI7xNALMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/sync-cookies.vue").then(m => m.default || m)
  },
  {
    name: "tuning_ak___uk",
    path: "/tuning_ak",
    meta: indexIF7nhDM33EMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/tuning_ak/index.vue").then(m => m.default || m)
  },
  {
    name: "tuning_ak___ru",
    path: "/ru/tuning_ak",
    meta: indexIF7nhDM33EMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/tuning_ak/index.vue").then(m => m.default || m)
  },
  {
    name: "tuning_ar___uk",
    path: "/tuning_ar",
    meta: indexgXSpbKwNwZMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/tuning_ar/index.vue").then(m => m.default || m)
  },
  {
    name: "tuning_ar___ru",
    path: "/ru/tuning_ar",
    meta: indexgXSpbKwNwZMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/tuning_ar/index.vue").then(m => m.default || m)
  },
  {
    name: "ua-any___uk",
    path: "/ua/:any(.*)*",
    meta: _91_46_46_46any_936Dss2Q9wJVMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/ua/[...any].vue").then(m => m.default || m)
  },
  {
    name: "ua-any___ru",
    path: "/ru/ua/:any(.*)*",
    meta: _91_46_46_46any_936Dss2Q9wJVMeta || {},
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/ua/[...any].vue").then(m => m.default || m)
  },
  {
    name: "uhoda-korystuvacha___uk",
    path: "/uhoda-korystuvacha",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/uhoda-korystuvacha/index.vue").then(m => m.default || m)
  },
  {
    name: "uhoda-korystuvacha___ru",
    path: "/ru/uhoda-korystuvacha",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/uhoda-korystuvacha/index.vue").then(m => m.default || m)
  },
  {
    name: "uslovyia-pryobrytenyia-oruzhyia___uk",
    path: "/uslovyia-pryobrytenyia-oruzhyia",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/uslovyia-pryobrytenyia-oruzhyia/index.vue").then(m => m.default || m)
  },
  {
    name: "uslovyia-pryobrytenyia-oruzhyia___ru",
    path: "/ru/uslovyia-pryobrytenyia-oruzhyia",
    component: () => import("/builds/ibis_dev/ibis_frontend/pages/uslovyia-pryobrytenyia-oruzhyia/index.vue").then(m => m.default || m)
  }
]