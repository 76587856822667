// import { useUserStore } from '~/stores/user'
import { useCartStore } from '~/stores/cart'
import { usePreorderStore } from '~/stores/preorder'
import { useAuthStore } from '~~/stores/auth'
// import { useComparisonStore } from '~/stores/comparison'
import { getCartToken, getPreorderCartToken } from '~~/utils/helpers/auth'

export default defineNuxtRouteMiddleware(to => {
  if (process.server) {
    return
  }
  if (to.path.includes('/cabinet')) {
    useUserStore().fetchUserDataIfNotExist(false, to)
  }
  if (to.path.includes('/checkout')) {
    if (!useAuthStore().loggedIn) {
      const tokenValue = getCartToken()
      const preorderTokenValue = getPreorderCartToken()
      if (preorderTokenValue && !usePreorderStore().cart) {
        usePreorderStore().fetchCart({ tokenValue: preorderTokenValue })
      }
      if (tokenValue && !useCartStore().cart) {
        useCartStore().fetchCartItemsCount({ tokenValue })
        useCartStore().fetchCart({ tokenValue })
      }
    } else {
      useUserStore().fetchUserDataIfNotExist(false, to)
    }
  }
  // useUserStore().fetchUserDataIfNotExist(false, to)
  // if (!useAuthStore().loggedIn) {
  //   const tokenValue = getCartToken()
  //   const preorderTokenValue = getPreorderCartToken()
  //   if (preorderTokenValue && !usePreorderStore().cart) {
  //     usePreorderStore().fetchCart({ tokenValue: preorderTokenValue })
  //   }
  //   if (tokenValue && !useCartStore().cart) {
  //     useCartStore().fetchCartItemsCount({ tokenValue })
  //     useCartStore().fetchCart({ tokenValue })
  //   }
  // } else {
  //   useComparisonStore().fetchComparisonOnce()
  // }
})
