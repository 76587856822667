import api from '../api.client'

const path = '/api/v2/shop/product-reviews'
const variantPath = '/api/v2/shop/product-variant-reviews'

export default {
  getAll: (params, options) => api(options).get(path, params),
  getOne: (code, options) => api(options).get(`${path}/${code}`),
  create: (payload, options) => api(options).post(path, payload),
  addMedia: (id, file, options) =>
    api(options).post(`${path}/${id}/media`, file),

  getRecently: options =>
    api({ auth: false, ...options }).get(
      '/api/v2/shop/recently-product-reviews?customer=default&itemsPerPage=15'
    ),
  getVariantReviews: (params, options) => api(options).get(variantPath, params)
}
