<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 119.62"
    width="16"
    height="16"
    fill="#395dff"
    xml:space="preserve"
  >
    <g>
      <path
        d="M26.24,110.8l7.59-5.87l8.64,4.17l-3.23-9.02l6.64-6.92l-9.58,0.3l-4.54-8.45l-2.69,9.21l-9.45,1.7l7.93,5.4L26.24,110.8 L26.24,110.8L26.24,110.8L26.24,110.8L26.24,110.8z M61.44,119.62l3.63-8.88l9.56-0.71l-7.31-6.2l2.29-9.32l-8.16,5.05l-8.16-5.05 l2.29,9.32l-7.33,6.2l9.56,0.71L61.44,119.62L61.44,119.62L61.44,119.62L61.44,119.62L61.44,119.62z M33.17,52.47 c4.44-1.66,10.11-3.78,14.19-6.31c4.68,4.34,7.72,9.28,7.96,15.28H67.6c0.24-6,3.28-10.94,7.96-15.28 c4.08,2.53,9.75,4.64,14.19,6.31c13.08,4.89,6.69,13.82-0.94,21.34c-7,6.89-16.67,11.15-27.36,11.15S41.1,80.71,34.09,73.81 C26.57,66.4,20,57.39,33.17,52.47L33.17,52.47z M51.82,40.06c0.2-1.64-4.64-7.85-5.52-10.84c-1.9-3-2.57-7.8-0.5-10.98 c0.83-1.26,0.47-3.52,0.47-5.25c0-17.31,30.33-17.33,30.33,0c0,2.19-0.5,3.86,0.69,5.58c1.99,2.88,0.96,7.99-0.72,10.67 c-1.07,3.13-6.15,9.06-5.79,10.84C71.08,48.95,51.75,48.66,51.82,40.06L51.82,40.06L51.82,40.06L51.82,40.06z M122.88,84.55 l-5.87-7.58l4.17-8.64l-9.03,3.24l-6.92-6.64l0.3,9.58l-8.45,4.54l9.21,2.68l1.7,9.45l5.39-7.93L122.88,84.55L122.88,84.55 L122.88,84.55L122.88,84.55L122.88,84.55z M96.64,110.8l-1.29-9.51l7.93-5.4l-9.44-1.71l-2.68-9.21l-4.54,8.45l-9.59-0.3l6.64,6.92 l-3.26,9.04l8.64-4.17L96.64,110.8L96.64,110.8L96.64,110.8L96.64,110.8L96.64,110.8z M0,84.54l9.51-1.29l5.4,7.93l1.71-9.44 l9.21-2.68l-8.45-4.54l0.3-9.58l-6.93,6.64L1.7,68.33l4.17,8.64L0,84.54L0,84.54L0,84.54L0,84.54L0,84.54z"
      />
    </g>
  </svg>
</template>
