import { useUserStore } from '~/stores/user'
import { useAuthStore } from '~/stores/auth'
import { getCartToken, getPreorderCartToken } from '~/utils/helpers/auth'
import { usePreorderStore } from '~/stores/preorder'
import { useCartStore } from '~/stores/cart'
import { useComparisonStore } from '~/stores/comparison'

export default function () {
  if (import.meta.server || process.server) {
    return
  }
  useUserStore().fetchUserDataIfNotExist(false)
  if (!useAuthStore().loggedIn) {
    const tokenValue = getCartToken()
    const preorderTokenValue = getPreorderCartToken()
    if (preorderTokenValue && !usePreorderStore().cart) {
      usePreorderStore().fetchCart({ tokenValue: preorderTokenValue })
    }
    if (tokenValue && !useCartStore().cart) {
      useCartStore().fetchCartItemsCount({ tokenValue })
      useCartStore().fetchCart({ tokenValue })
    }
  } else {
    useComparisonStore().fetchComparisonOnce()
  }
}
